<template>
  <div class="videocont ">
    <iframe
      :src="checkURL"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      class="iframe"
      :title="title"
    ></iframe>
  </div>
</template>

<script>
  export default {
    name: 'Video',
    props: {
      url: {
        type: String,
        default:
          'https://player.vimeo.com/video/684906838?h=5cf340bd7d&amp;amp;amp;badge=0&amp;amp;amp;autopause=0&amp;amp;amp;player_id=0&amp;amp;amp;app_id=58479',
      },
      title: {
        type: String,
        default: 'Hello World.mpg',
      },
    },

    computed: {
      checkURL() {
        return this.url.replace(
          'https://vimeo.com/',
          'https://player.vimeo.com/video/'
        )
      },
    },
  }
</script>

<style scoped>
  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .videocont {
    position: relative;
    height: auto;
  }
</style>
